@import "../../../variables";

.main {
    min-height: 82vh;
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title {
    color: $title;
    font-weight: 600;
    text-align: center;
}

.text {
    font-size: 1.2rem;
    font-weight: 300;
    margin: 20px 0;
}

.email {
    color: $email;
    font-size: 1.2rem;
}

@media only screen and (max-width: 450px) {
    .title {
        font-size: 1.5rem;
    }
    .text {
        margin: 40px 0;
        font-size: 1rem;
    }
}
