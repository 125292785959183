/* Header */
$header-background: #303030;
$navigation-link: rgb(189, 189, 189);

/* Top */
/* Top Main */
$external-url: #0066cc;
$my-name: rgb(40, 40, 40);
$job-title: rgb(67, 67, 67);
$description: rgb(79, 79, 79);
/* About */
/* ABout Main */
$heading: #303030;
$paragraph: #535151;
/* ABout Main UseSkill*/
$text: #535351;
/* Projects */
$title: #303030;
$heading: #303030;
$team: rgb(235, 235, 235);
$external-url: #0066cc;
$description: #303030;
$item-hover-background: rgba(0, 0, 0, 0.886);
/* Contact */
$title: #303030;
$email: #303030;
