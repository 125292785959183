@import "../Footer/variables";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

.footer {
    height: 9vh;
    color: grey;
    font-family: "Poppins";
    font-size: 0.9rem;
    font-weight: 200;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 540px) {
    .footer {
        font-size: 0.7rem;
    }
}
