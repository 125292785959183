@import "../../../../variables";

.skillBox {
    list-style: none;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}
.skill {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 30px;
}

.skillImg {
    width: 45px;
}

.skillText {
    color: $text;
    font-size: 0.8rem;
    font-weight: 300;
}
