@import "../../../variables";

.main {
    min-height: 82vh;
    padding: 0 60px 30px 60px;
}

.title {
    color: $title;
    text-align: center;
    padding-top: 50px;
}

.itemBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
